import React from 'react'
import * as styles from './member-tile.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const MemberTile = ({ member }) => {
  return (
    <div className={styles.memberTile}>
      {member.photo?.gatsbyImage && (
        <GatsbyImage
          image={member.photo.gatsbyImage}
          alt={member?.fullName}
        />
      )}
      {member.personalSite && (
        <a
          href={member.personalSite}
          target="_blank"
          rel="noreferrer"
          className={styles.memberName}>
          <p>{member?.fullName}</p>
        </a>
      )}
      {!member.personalSite && (
        <p className={styles.memberName}>{member?.fullName}</p>
      )}
      <p>{member?.title}</p>
    </div>
  )
}

export default MemberTile
