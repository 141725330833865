import React from 'react'

import * as styles from './green-container.module.css'

const GreenContainer = ({ children, isNarrow = false }) => {
  const maxWidth = isNarrow ? 'var(--size-narrow-width)' : 'var(--size-max-width)';
  return (
    <div className={styles.container}>
      <div
        style={{
          maxWidth,
          margin: '0 auto',
          padding: 'var(--space-4xl) var(--size-gutter)',
        }}
        >
        {children}
      </div>
    </div>
  )
}

export default GreenContainer
