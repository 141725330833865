import React from 'react'
import { graphql, Link } from 'gatsby'
import { get, trim, uniqBy } from 'lodash'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Layout from '../components/layout'
import MemberTile from '../components/member-tile'

import Container from '../components/container'
import GreenContainer from '../components/green-container'
import * as styles from './people.module.css'

class People extends React.Component {
  render() {
    const models = uniqBy(
      get(this, 'props.data.allContentfulPerson.nodes'),
      'name'
    )
    const team = get(this, 'props.data.allContentfulTeamMember.nodes')
    const peopleContent = get(this, 'props.data.allContentfulPage.nodes[0].pageDescription', {});

    return (
      <Layout location={this?.props?.location}>
        <GreenContainer isNarrow>
          <p>Thank you for being a part of this:</p>
          {models.map((person, i) => {
            return (
              <span key={person.id}>
                <Link
                  to={`/${person?.primaryCharacterLetter?.slug}`}
                  style={{fontSize: '0.9rem'}}>
                  {trim(person?.name)}
                </Link>
                {i !== models.length - 1 ? ', ' : ''}
              </span>
            )
          })}
          {peopleContent?.raw && (<p style={{marginTop: 'var(--space-3xl)', lineHeight: '2'}}>
            {renderRichText(peopleContent)}
          </p>)}
          <p style={{ marginTop: '3rem' }}>Thank you for making this happen:</p>
          {team.map((member, i) =>
            i !== team.length - 1
              ? <span style={{fontSize: '0.9rem'}} key={i}>{trim(member?.fullName)}, </span>
              : <span style={{fontSize: '0.9rem'}} key={i}>{trim(member?.fullName)}</span>
          )}
        </GreenContainer>
        <Container isNarrow>
          <div className={styles.teamContainer}>
            {team.map((member) => (
              <MemberTile member={member} key={member?.id} />
            ))}
          </div>
        </Container>
      </Layout>
    )
  }
}

export default People

export const pageQuery = graphql`
  query PeopleQuery {
    allContentfulTeamMember(sort: { fields: fullName }) {
      nodes {
        fullName
        id
        title
        personalSite
        photo {
          gatsbyImage(width: 500)
        }
      }
    }
    allContentfulPerson(sort: { fields: name }) {
      nodes {
        name
        id
        primaryCharacterLetter {
          slug
        }
      }
    }
    allContentfulPage(filter: {pageName: {eq: "People"}}) {
      nodes {
        pageDescription {
          raw
        }
      }
    }
  }
`
